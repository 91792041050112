import React from 'react';

export const EC_FAQS = {
  title: "FAQs",
  description: "Guides and FAQs.",
  categories: [
    {
      title: "Elite Case",
      collapsed: true,
      url: "#case",
      questions:[
        {
          title: "How do I install the screen protector using the Elite case box?",
          answer:`
            <p>Watch this video to see the quick installation instructions (same for Classic case).</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/989814320" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            <p>You can find the written PDF instructions <a href="/resources/pdf/15NG-Bare-Instructions-v6.pdf" target="_blank">here</a>.</p>
          `,
          url: "#cg-install"
        },
        {
          title: "How do I swap the Elite case accent colors?",
          answer:`
            <p>Watch this video to see the instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/1010031158" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
          `,
          url: "#cg-accents"
        },
        {
          title: "How do I extend the size the Elite case fingerloop?",
          answer:`
            <p>Watch this video to see the instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/1010437020" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
          `,
          url: "#cg-fingerloop"
        },
        {
          title: "How do I install the lens protector?",
          answer:`
            <p>Watch this video to see the instructions.</p>
            <iframe class="landscape" src="https://player.vimeo.com/video/1010036851" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
          `,
          url: "#cg-lp"
        },
        {
          title: "Will the Elite case magnets affect the phone?",
          answer:`
            <p>No. The Magnets have been strategically placed so that functions such as WiFi, LTE, GPS, and wireless charging work without any issues.</p>
          `,
          url: "#cg-function"
        },
        {
          title: "Will the Elite case magnets erase my credit cards?",
          answer:`
            <p>No. Common credit cards are safe and are NOT affected.</p>
          `,
          url: "#cg-cards"
        },
        {
          title: "Does the Elite case support wireless charging?",
          answer:`
            <p>Yes, the case supports wireless charging and any MagSafe accessories.</p>
          `,
          url: "#cg-charging"
        },
        {
          title: "Do all Elite cases work with the MagBak Wallet?",
          answer:`
            <p>Yes. All MagBak Elite cases work with the MagBak Wallet:</p>
          `,
          url: "#cg-wallet"
        },
        {
          title: "Does the Elite case work with Apple's MagSafe wallet?",
          answer:`
            <p>Yes! Magnetic attachment is supported.</p>
            <p>If you are looking for a the best case + wallet solution check out <a target="_blank" href="https://magbak.com/products/magbak-wallet">MagBak wallet</a>.</p>
          `,
          url: "#cg-magsafe-wallet"
        },
        {
          title: "Does the Elite case work with Apple's MagSafe charger?",
          answer:`
            <p>Yes! Charging using Apple's MagSafe charger works with MagBak case.</p>
          `,
          url: "#cg-magsafe-charger"
        },
        {
          title: "Which MagStick should I use with my Elite case?",
          answer:`
            <p>The flat MagStick can be used with any case.</p>
            <img src="/resources/img/magstick-profile.jpg"/>
          `,
          url: "#cg-magstick"
        },
        {
          title: "What material is the Elite case made out of?",
          answer:`
            <p>The case is made out of a Policarbonate (PC) frame that is overmolded with a thermoplastic rubber (TPU). A soft-touch finish is added to provide grip and a smooth feel.</p>
          `,
          url: "#cg-material"
        },
        {
          title: "What are the dimensions of the Eliete case?",
          answer:`
          <p>MagBak Elite Case for iPhone 16 series:</p>
          <p>
            <ul>
              <li><a target="_blank" href="/resources/pdf/EC-16-Pro-Max-dimensions.pdf" title="iPhone 16 Pro Max Elite Case Dimensions">iPhone 16 Pro Max Elite Case Dimensions</a></li>
              <li><a target="_blank" href="/resources/pdf/EC-16-Pro-dimensions.pdf" title="iPhone 16 Pro Elite Case Dimensions">iPhone 16 Pro Elite Case Dimensions</a></li>
            </ul>
          </p>
          `,
          url: "#cg-dimensions"
        }
      ]
    }
  ]
};



export const EC_FAQS_CATEGORY_INDEX = EC_FAQS.categories.map((category)=>{
  return {
    title: category.title,
    questions: category.questions.map((question)=>{
      let elem = question.answer
      // console.log("INNTER TEXT: ", elem);
      return {
        title: question.title,
        answer: question.answer.innerText
      }
    })
  };
});
