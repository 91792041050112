import React from 'react';
import { dot } from '../../lib/obj';

export const PRODUCTS = {
  title: "Products",
  description: "MagBak products",
  categories:[
    {
      title: "MagBak Elite Case for iPhone",
      variants: [
        {
          title: "Model",
          options: [
            "16 Pro Max",
            "16 Pro",
            "15 Pro Max",
            "15 Pro"
          ]
        },
        {
          title: "Color",
          options:[
            "Black",
            "White"
          ]
        }
      ],
      keywords:[
        "elite",
        "mount",
        "ios",
        "cover",
        "phone",
        "magsafe"
      ]
    },
    {
      title: "MagBak Classic Case for iPhone",
      variants: [
        {
          title: "Model",
          options: [
            "16 Pro Max",
            "16 Pro",
            "16 Plus",
            "16",
            "15 Pro Max",
            "15 Pro",
            "15 Plus",
            "15"
          ]
        },
        {
          title: "Color",
          options:[
            "Black",
            "Blue",
            "Gray",
            "Clear",
          ]
        }
      ],
      keywords:[
        "mount",
        "ios",
        "cover",
        "phone",
        "magsafe",
        "classic"
      ]
    },
    {
      title: "MagBak Case for iPhone",
      variants: [
        {
          title: "Model",
          options: [
            "14 Pro Max",
            "14 Pro",
            "14 Plus",
            "14",
            "13 Pro Max",
            "13 Pro",
            "13",
            "13 mini",
            "12 Pro Max",
            "12 Pro",
            "12",
            "12 mini",
            "11 Pro Max",
            "11 Pro",
            "11",
            "Xs Max",
            "X / Xs",
            "XR",
            "SE",
            "8 Plus",
            "8",
            "7 Plus",
            "7",
            "6s Plus",
            "6s"
          ]
        },
        {
          title: "Color",
          options:[
            "Black",
            "Blue",
            "Clear",
            "Gray",
            "Red"
          ]
        }
      ],
      keywords:[
        "mount",
        "ios",
        "cover",
        "phone",
        "magsafe"
      ]
    },
    {
      title: "MagBak Case for Google",
      variants: [
        {
          title: "Model",
          options: [
            "Pixel 8 Pro",
            "Pixel 7 Pro",
            "Pixel 6 Pro"
          ]
        },
        {
          title: "Color",
          options:[
            "Black"
          ]
        }
      ],
      keywords:[
        "mount",
        "android",
        "cover",
        "phone",
        "magsafe"
      ]
    },
    {
      title: "MagBak Case for Samsung",
      variants: [
        {
          title: "Model",
          options: [
            "S24 Ultra",
            "S23 Ultra",
            "S22 Ultra",
            "S22 Plus"
          ]
        },
        {
          title: "Color",
          options:[
            "Black",
            "Blue"
          ]
        }
      ],
      keywords:[
        "mount",
        "android",
        "cover",
        "phone",
        "magsafe",
        "galaxy"
      ]
    },
    {
      title: "MagBak Wallet",
      variants: [
        {
          title: "Color",
          options:[
            "Saffiano Black",
            "Nappa Black",
            "Tan",
            "Saffiano Blue",
            "Blue",
            "Saffiano Red",
            "Red"
          ]
        }
      ],
      keywords:[
        "wallet",
        "pruse",
        "stand",
        "strap",
        "magsafe"
      ],
      shipMethods:[
        {
          name: "USPS Standard Shipping - $5.00 (US Only)",
          subnote: "Delivers within 12-15 business days **"
        },
        {
          name: "FedEx 2-day - $7.00 (US only)",
          subnote: "Delivers within 2 business days **"
        },
        {
          name: "FedEx Priority - $15.00 (International)",
          subnote: "Delivers within 5-7 business days **"
        }
      ]
    },
    {
      title: "Tesla Charger For Model 3 & Y",
      variants: [
        {
          title: "Side",
          options:[
            "Left Hand",
            "Right Hand"
          ]
        }
      ],
      keywords:[
        "wireless",
        "qi",
        "auto",
        "tesla",
        "mount",
        "magsafe"
      ]
    },
    {
      title: "RimCase For Tesla",
      variants: [
        {
          title: "Wheel",
          options:[
            "Model Y 20\" Induction",
            "Model Y 21\" Uberturbine",
            "Model 3 19\" Sport Wheel",
            "Model 3 20\" Uberturbine",
          ]
        }
      ],
      keywords:[
        "rim",
        "wheel",
        "protector"
      ],
      shipMethods:[
        {
          name: "FedEx Economy - Free (US Only)",
          subnote: "Delivers within 5-7 business days **"
        },
        {
          name: "FedEx Express 2-day - $15.00 (US only)",
          subnote: "Delivers within 2 business days **"
        },
        {
          name: "FedEx Priority - $20.00 (International)",
          subnote: "Delivers within 5-7 business days **"
        }
      ]
    },
    {
      title: "MagBak MultiCharger",
      variants: [],
      keywords:[
        "3",
        "three",
        "wireless",
        "qi",
        "stand",
        "magsafe"
      ]
    },
    {
      title: "MagBak Charger",
      variants: [
        {
          title: "Kit",
          options:[
            "Car Kit",
            "Home US Kit",
            "Home UK Kit",
            "Standalone"
          ]
        }
      ],
      keywords:[
        "wireless",
        "qi",
        "auto",
        "truck",
        "house"
      ]
    },
    {
      title: "MagStache",
      variants: [
        {
          title: "Color",
          options:[
            "Black",
            "Blue",
            "Red",
            "White"
          ]
        }
      ],
      keywords:[
        "mustache",
        "mount",
        "strip",
        "magstrip",
        "magnet",
        "rubber",
        "pads"
      ]
    },
    {
      title: "MagBak Halo Phone Sanitizer",
      variants: [],
      keywords:[
        "uvc",
        "uv-c",
        "clean",
        "phone",
        "30",
        "seconds"
      ]
    },
    {
      title: "MagBak Bridge",
      variants: [
        {
          title: "Bundle",
          options:[
            "+ MagStick",
            "+ 30W Car USB-C Plug",
            "+ 20W Home USB-C Plug"
          ]
        }
      ],
      keywords:[
        "adapter",
        "magsafe",
        "apple"
      ]
    },
    {
      title: "Screen Protector",
      variants: [
        {
          title: "Model",
          options: [
            "iPhone 16 Pro Max",
            "iPhone 16 Pro",
            "iPhone 16 Plus",
            "iPhone 16",
            "iPhone 15 Pro Max",
            "iPhone 15 Pro",
            "iPhone 15 Plus",
            "iPhone 15",
            "iPhone 14 Pro Max",
            "iPhone 14 Pro",
            "iPhone 14 Plus",
            "iPhone 14",
            "iPhone 13 Pro Max",
            "iPhone 13 Pro",
            "iPhone 13",
            "iPhone 13 mini",
            "iPhone 12 Pro Max",
            "iPhone 12 Pro",
            "iPhone 12",
            "iPhone 12 mini",
            "iPhone 11 Pro Max",
            "iPhone 11 Pro",
            "iPhone 11",
            "iPhone Xs Max",
            "iPhone X / Xs",
            "iPhone XR"
          ]
        }
      ],
      keywords: [
        "curved",
        "flat"
      ]
    },
    {
      title: "Lens Protector",
      variants: [
        {
          title: "Model",
          options: [
            "iPhone 16 Pro Max",
            "iPhone 16 Pro",
            "iPhone 16 Plus",
            "iPhone 16",
            "iPhone 15 Pro Max",
            "iPhone 15 Pro"
          ]
        }
      ],
      keywords: [
        "camera",
        "guard",
        "bumper"
      ]
    },
    {
      title: "USB Charger",
      variants: [
        {
          title: "Model",
          options: [
            "Car USB-C 30W",
            "Home USB-C 20W",
            "Car QC 3.0",
            "US Home QC 3.0",
            "UK Home QC 3.0"
          ]
        }
      ],
      keywords: [
        "usbc",
        "plug",
        "usb",
        "adapter"
      ]
    },
    {
      title: "MagStick",
      variants: [],
      keywords: [
        "magnet",
        "mount",
        "mag",
        "stick",
        "magstick"
      ]
    },
    {
      title: "MagBak KeyTag",
      variants: [],
      keywords: [
        "keys",
        "holder",
        "airtag",
        "aluminum",
        "metal"
      ]
    },
    {
      title: "Accent Color Pack",
      variants: [
        {
          title: "Color",
          options:[
            "Black",
            "Blue",
            "Light Blue",
            "Light Gray",
            "Pink",
            "Purple",
            "Red",
            "White",
            "Yellow"
          ]
        }
      ],
      keywords: [
        "case",
        "elite",
        "colors",
        "buttons",
        "fingerloop",
        "grips",
        "pinkypillow"
      ]
    },
    {
      title: "Buttons Color Pack",
      variants: [],
      keywords: [
        "case",
        "classic",
        "colors",
        "buttons"
      ]
    },
    {
      title: "Wrist Strap",
      variants: [
        {
          title: "Color",
          options:[
            "Black",
            "Blue",
            "Pink",
            "Purple",
            "Red",
            "White"
          ]
        }
      ],
      keywords: [
        "lanyard",
        "elite",
        "band",
        "anchor"
      ]
    }
  ]
};


export const VARIANT_MAP = {};
export const PRODUCT_INDEX = PRODUCTS.categories.map((category)=>{
  let index = {
    title: category.title,
    options: [],
    keywords: category.keywords || [],
    shipMethods: category.shipMethods
  };

  let variants = category.variants;
  if (variants) {
    let vcs = variantMultiplier([category.title],dot(variants,[0,'options']));
    for (var i = 1; i < variants.length; i++) {
      vcs = variantMultiplier(vcs, dot(variants,[i,'options']));
    }
    index.options = vcs;
    vcs.forEach((vc, i) => {
      VARIANT_MAP[vc] = index;
    });
  }

  return index;
});




function variantMultiplier(vas = [], vbs = []) {
  if (vas.length == 0) {
    return vbs;
  }
  if (vbs.length == 0) {
    return vas;
  }
  let vcs = [];
  vas.forEach((a) => {
    vbs.forEach((b) => {
      vcs.push(`${a} ${b}`)
    });
  });
  return vcs;
}
