import React from 'react';
import Page from './Page'
import Separator from '../elements/Separator';
import { Link } from 'react-router-dom';

import './Page.css';
import './MBCStartPage.css';

import FAQLayout from '../layouts/FAQLayout';
import { MBC_FAQS } from '../../constants/support/MBCFAQs'
import { dot } from '../../lib/obj'

class MBCStartPage extends Page {
  render() {
    return (
      <div className="MBCStartPage Page">
        <p>{MBC_FAQS.description}</p>
        <Separator></Separator>
        <FAQLayout faq={MBC_FAQS} question={dot(this.props,"query.question")}/>
        <Link className="Link" to="/faq">See all FAQs</Link>
      </div>
    );
  }
}

export default MBCStartPage
