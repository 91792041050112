import React from 'react';
import { withRouter } from 'react-router'
import Page from './Page'
import Separator from '../elements/Separator';
import PageHeaderOutline from '../layouts/PageHeaderOutline';
import Input from '../elements/Input';

import './Page.css';
import './SearchPage.css';

import { dot } from '../../lib/obj';
import { _ } from "../../lib/underscore";
import { financial, trim } from '../../lib/formats';
import { searchMatch, extractHTMLText } from '../../lib/search';

import { PRODUCT_FAQS } from '../../constants/support/FAQs';

class SearchPage extends Page {

  constructor(props) {
    super(props);
    this.state = {
      term: props.term || "",
      temp: props.term || "",
    };
  }

  onSearch(term) {
    this.setState({term:term});
  }

  onChange(temp) {
    //this.setState({temp:temp});
    this.onSearch(temp); 
  }

  onEnter(evt) {
    this.onSearch(this.state.temp);
    evt.preventDefault();
  }

  gotoQuestion(evt, id) {
    if (id) {
      let elem = document.querySelector(`#${id} > .Answer `);
      if (elem) {
        const y = elem.getBoundingClientRect().top + window.scrollY  - 125;
        window.scroll({
          top: y,
          behavior: 'smooth'
        });
      }
      else {
        this.props.history.push(`/faq?question=${id}`)
      }
    }
    evt.preventDefault();
  }

  render() {

    let faq = this.props.faq || PRODUCT_FAQS;
    let categories = faq.categories || [];
    let term = trim(this.state.term);

    let results = <div className="Results">No Results</div>;

    if (term && term.length > 1) {
      categories = categories.map((category)=>{
        let questions = _.compact((category.questions || []).map((question)=>{
          let q = {
             title: searchMatch(term, question.title,{decorate:(f)=>`<b>${f}</b>`}),
             answer: searchMatch(term,extractHTMLText(question.answer),{decorate:(f)=>`<b>${f}</b>`})
          }
          if (q.title || q.answer) {
            return {
              title: q.title || question.title,
              answer: q.answer,
              url: question.url
            }
          }
          return null;
        }));
        return { questions: questions };
      });
      categories = categories.filter((category)=>category.questions.length != 0)
    } else {
      categories = [];
      results = <div className="Results Error">Enter longer search text</div>;
    }

    // Render the results
    let resultCtr=0;
    if (categories.length > 0) {
      results = <div className="Results">
        {categories.map((category, index)=>{
          return <div key={`category${index}`} className="Category" data-collapsed={category.collapsed}>
            {(category.questions || []).map((question, qi)=>{
              let qid = question.url ? question.url.replace(/^#/,'') : question.url;
              return <div key={`question${qi}`} className="Question" onClick={(evt)=>{this.gotoQuestion(evt, qid)}}>
                <span className="Index">{++resultCtr}.</span>
                <span className="Title" dangerouslySetInnerHTML={{__html:question.title}}></span>
                <span className="Answer" dangerouslySetInnerHTML={{__html:question.answer}}></span>
              </div>
            })}
          </div>
        })}
      </div>
    }

    return (
      <div className="SearchPage Page">
        <PageHeaderOutline title="Search" top={this._backButton()}></PageHeaderOutline>
        <p>Look for anything on the support site.</p>
        <Separator/>
        <form className="Filter" action="/search" onSubmit={()=>false}>
          <Input label="Search" name="term" value={this.state.temp} clearable="true" onChange={(value)=>{this.onChange(value)}} onEnter={(evt)=>{this.onEnter(evt)}}/>
        </form>
        {results}
      </div>
    );
  }
}

export default withRouter(SearchPage);
